import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
                    <h4>Copyright © 2024 Ingbell Chile SpA.</h4>
        </footer>
    );
};

export default Footer;
